const locales = [
  {
    code: 'en',
    name: 'English',
    icon: require('@/assets/svg/us.svg'),
  },
  {
    code: 'es',
    name: 'Español',
    icon: require('@/assets/svg/es.svg'),
  },
  {
    code: 'pt',
    name: 'Português',
    icon: require('@/assets/svg/pt.svg'),
  },
  {
    code: 'ro',
    name: 'Română',
    icon: require('@/assets/svg/ro.svg'),
  },
  {
    code: 'de',
    name: 'Deutsch',
    icon: require('@/assets/svg/de.svg'),
  },
  {
    code: 'fr',
    name: 'Français',
    icon: require('@/assets/svg/fr.svg'),
  },
  {
    code: 'it',
    name: 'Italiano',
    icon: require('@/assets/svg/it.svg'),
  },
];

export default locales;
