<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon
          :icon="icon" 
          custom-size="default"
        />
        {{ title }}
      </p>
    </header>
    <div class="card-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
