<template>
  <b-dropdown-item
    :value="item"
    aria-role="listitem"
  >
    <div class="media">
      <div class="media-left">
        <b-image
          class="switch-locale-image"
          :src="item.icon"
        />
      </div>
      <div class="media-content">
        {{ item.name }}
      </div>
    </div>
  </b-dropdown-item>
</template>

<script>
export default {
  name: 'SwitchLocaleItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
