<template>
  <div class="switch-locale">
    <b-dropdown
      :value="currentMenu"
      position="is-bottom-left"
      :mobile-modal="false"
      aria-role="list"
      @change="handleChange"
    >
      <template #trigger>
        <b-button
          :label="currentMenu.name"
          type="is-primary"
          icon-right="menu-down"
        />
      </template>
      <switch-locale-item
        v-for="item in menus"
        :key="item.code"
        :item="item"
      />
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import availablelocales from "@/common/locales";
import SwitchLocaleItem from "@/components/SwitchLocaleItem";

export default {
  name: 'SwitchLocale',

  components: {
    SwitchLocaleItem,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      menus: availablelocales,
    };
  },

  computed: {
    ...mapGetters('config', ['defaultApiLocale']),

    currentMenu() {
      return availablelocales.find(locale => locale.code === this.value);
    },
  },

  methods: {
    handleChange(value) {
      this.$router.push({
        name: this.$router.name,
        ...this.$router.params,
        ...(value.code !== this.defaultApiLocale) && {
          query: {
            language: value.code,
          },
        },
      }).then(() => {
        this.$emit("change", value.code);
      });
    },
  }
};
</script>
