<template>
  <section class="section is-breadcrumb">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <ul>
            <li 
              v-for="(item, index) in items" 
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Breadcrumb',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
