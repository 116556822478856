<template>
  <promised :promise="promise">
    <template #pending>
      <b-loading 
        :active="true"
        :is-full-page="false"
      />
    </template>
    <template #default>
      <breadcrumb :items="breadcrumbs" />
      <hero>
        {{ category.title }}
        <switch-locale
          slot="right" 
          v-model="locale"
          @change="handleLocaleChange"
        />
      </hero>
      <section class="section is-main-section">
        <card
          :title="category.title"
          icon="pencil-outline"
        >
          <tabs>
            <template #items>
              <b-tab-item
                label="Data"
                icon="database"
              >
                <validation-observer 
                  v-slot="{ handleSubmit, pristine }"
                  ref="form"
                  tag="form"
                >
                  <input-with-validation
                    v-model="form.title"
                    name="title"
                    rules="required|max:255"
                    label="Title"
                    icon="format-title"
                    maxlength="255"
                    horizontal
                    @input="createSlug"
                  />
                  <input-with-validation
                    v-model="form.abbreviation"
                    name="abbreviation"
                    :rules="{ required: isDefaultLocale ? form.navigation === 1 : category.navigation === 1, max: 255 }"
                    label="Abbreviation"
                    icon="content-cut"
                    maxlength="255"
                    horizontal
                  />
                  <input-with-validation
                    v-model="form.description"
                    name="description"
                    label="Description"
                    type="textarea"
                    horizontal
                  />
                  <input-with-validation
                    v-model="form.slug"
                    name="slug"
                    rules="required|max:255|slug"
                    label="Slug"
                    icon="link"
                    maxlength="255"
                    horizontal
                  />
                  <select-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.parent_id"
                    name="parent_id"
                    label="Parent"
                    horizontal
                  >
                    <option value="">
                      None
                    </option>
                    <option
                      v-for="item in parentCategories"
                      :key="item.distribution_id"
                      :value="item.distribution_id"
                    >
                      {{ item.title }}
                    </option>
                  </select-with-validation>
                  <select-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.navigation"
                    name="navigation"
                    label="Navigation"
                    horizontal
                  >
                    <option :value="1">
                      Yes
                    </option>
                    <option :value="0">
                      No
                    </option>
                  </select-with-validation>
                  <b-field
                    v-if="isDefaultLocale && mappedAssets.length"
                    label="Assets"
                    horizontal
                  >
                    <div class="control">
                      <image-gallery-slideshow :data="mappedAssets" />
                    </div>
                  </b-field>
                  <upload-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.icon"
                    name="icon"
                    rules="ext:svg,png"
                    accept=".svg,.png"
                    horizontal
                  >
                    Click to upload (icon)
                  </upload-with-validation>
                  <hr>
                  <b-field horizontal>
                    <b-field grouped>
                      <div class="control">
                        <b-button 
                          type="is-primary"
                          :disabled="pristine || loading"
                          @click="handleSubmit(submit)"
                        >
                          Submit
                        </b-button>
                      </div>
                    </b-field>
                  </b-field>
                </validation-observer>
              </b-tab-item>
            </template>
          </tabs>
        </card>
      </section>
      <b-loading 
        :active="loading" 
        :is-full-page="false" 
      />
    </template>
    <template #rejected>
      <section class="section is-main-section">
        <div class="content has-text-grey has-text-centered">
          <b-icon 
            icon="emoticon-sad" 
            size="is-large" 
          />
          <p>Something went wrong</p>
        </div>
      </section>
    </template>
  </promised>
</template>

<script>
import slugify from "slugify";
import { mapGetters, mapActions } from "vuex";
import { Promised } from "vue-promised";
import { ValidationObserver } from "vee-validate";
import availablelocales from "@/common/locales";
import pluck from "@/utils/pluck";
import buildFormDataMixin from "@/mixins/buildFormDataMixin";
import Breadcrumb from "@/components/Breadcrumb";
import Hero from "@/components/Hero";
import SwitchLocale from "@/components/SwitchLocale";
import Card from "@/components/Card";
import Tabs from "@/components/Tabs";
import InputWithValidation from "@/components/Validation/InputWithValidation";
import SelectWithValidation from "@/components/Validation/SelectWithValidation";
import UploadWithValidation from "@/components/Validation/UploadWithValidation";
import ImageGallerySlideshow from "@/components/ImageGallerySlideshow";
import Repository from "@/repositories/Repository";

export default {
  name: 'CategoriesEdit',

  components: {
    Promised,
    ValidationObserver,
    Breadcrumb,
    Hero,
    SwitchLocale,
    Card,
    Tabs,
    InputWithValidation,
    SelectWithValidation,
    UploadWithValidation,
    ImageGallerySlideshow,
  },

  mixins: [buildFormDataMixin],

  data() {
    return {
      promise: null,
      breadcrumbs: [
        'Admin', 
        'Edit Category',
      ],
      loading: false,
      locale: '',
      locales: [],
      category: {},
      form: {},
    };
  },

  computed: {
    ...mapGetters('config', ['defaultApiLocale']),
    ...mapGetters('data', ['parentCategories']),

    isDefaultLocale() {
      return this.locale === this.defaultApiLocale;
    },

    isEdit() {
      return this.locales.includes(this.locale);
    },

    mappedAssets() {
      return this.form.assets && this.form.assets.map(asset => ({
        src: `${process.env.VUE_APP_CDN_URL}/category-icons/${asset}`,
        width: 256,
        height: 256,
      }));
    },
  },

  created() {
    this.locale = this.defaultApiLocale;
    this.locales.push(this.locale);
    this.promise = this.fetchCategory(this.locale);
    this.promise.then(response => {
      const { title, parent, navigation } = response.data;
      this.category = {
        title,
        parent_id: parent ? parent.distribution_id : '',
        navigation: navigation || 0,
      };
      Object.keys(response.alternates || []).forEach(alternate => {
        this.locales.push(alternate);
      });
    }).then(() => {
      if (this.$route.query.language && availablelocales.find(locale => locale.code === this.$route.query.language)) {
        this.handleLocaleChange(this.$route.query.language);
      }
    });
  },

  methods: {
    ...mapActions('data', {
      translateCategoryAction: 'addCategory',
      editCategoryAction: 'editCategory',
    }),

    createSlug(value) {
      this.form.slug = slugify(value, {
        lower: true,
        strict: true,
      });
    },

    async fetchCategory(locale) {
      const response = await Repository.get('categories').find(this.$route.params.category, {
        language: locale,
        state: 'preview',
      });

      const {
        id,
        title, 
        abbreviation, 
        description, 
        slug, 
        parent,
        navigation,
        icon: assets,
      } = response.data;

      this.form = Object.assign({
        id,
        title,
        abbreviation: abbreviation || '',
        description: description || '',
        slug,
      }, this.isDefaultLocale ? {
        assets: [].concat(assets),
        icon: null,
        parent_id: parent ? parent.distribution_id : "",
        navigation: navigation || 0,
      } : {});

      return response;
    },

    handleLocaleChange(locale) {
      this.locale = locale;
      this.locales.includes(this.locale) ? this.promise = this.fetchCategory(this.locale) : this.resetForm();
    },

    async editCategory() {
      Object.keys(this.form).forEach((key) => (this.form[key] === null) && delete this.form[key]);

      const response = await this.editCategoryAction(this.buildFormData(Object.assign({}, this.form, {
        language: this.locale,
        parent_id: this.isDefaultLocale ? this.form.parent_id : this.category.parent_id,
        _method: 'PATCH',
      })));

      if (this.isDefaultLocale) {
        this.$set(this.category, 'parent_id', response.data.parent ? response.data.parent.distribution_id : '');
      }

      if (process.env.NODE_ENV === 'production') {
        let purgeUrls = [];
        if (this.form.icon) {
          purgeUrls = purgeUrls.concat(pluck(this.mappedAssets, 'src'));
        }
        purgeUrls.push(`${process.env.VUE_APP_API_URL}/categories/${this.form.slug}?language=${this.locale}`);
        Repository.get('purge').store(purgeUrls);
      }

      return Promise.resolve(response);
    },

    async translateCategory() {
      const response = await this.translateCategoryAction(this.buildFormData(Object.assign({}, this.form, {
        language: this.locale,
        category_id: this.$route.params.category,
      })));

      this.locales.push(this.locale);

      this.$set(this.form, 'id', response.data.id);

      return Promise.resolve(response);
    },

    submit() {
      this.loading = true;
      const promise = this.isEdit ? this.editCategory() : this.translateCategory();
      promise.then(() => {
        this.$buefy.toast.open({
          message: this.isEdit ? 'Edited successfully' : 'Created successfully',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-warning',
          position: 'is-bottom-right',
        });
        if (err.response) {
          this.$refs.form.setErrors(err.response.data.errors);
        }
      }).finally(() => {
        this.loading = false;
      });
    },

    resetForm() {
      this.form = {};
    },
  },
};
</script>
